const swipers = document.getElementsByTagName('swiper-container')
if (swipers.length) import('@/lib/swiper')
    .then(({ register }) => register())
    .then(() => {
        for (const swiperEl of swipers) {
            // Mark non-visible slides as inert:
            const updateInerts = () => {
                swiperEl.swiper.slides.forEach((slide) => {
                    if (slide.classList.contains('swiper-slide-visible')) slide.removeAttribute('inert')
                    else slide.setAttribute('inert', '')
                })
            }
            // Needed for marking offscreen slides as inert:
            swiperEl.setAttribute('watch-slides-progress', 'true')
            swiperEl.setAttribute('slide-visible-class', 'swiper-slide-visible')
            swiperEl.swiper.on('transitionEnd', updateInerts)
            swiperEl.swiper.once('progress', updateInerts) // called on init after visibility classes added

            // Control pause button if it exists
            const autoplayButton = swiperEl.querySelector('[data-swiper-pause]')
            if (!autoplayButton) return
            swiperEl.swiper.on('autoplayStart', () => {
                autoplayButton.setAttribute('aria-pressed', 'false')
            })
            swiperEl.swiper.on('autoplayStop', () => {
                autoplayButton.setAttribute('aria-pressed', 'true')
            })
            autoplayButton.addEventListener('click', () => {
                swiperEl.swiper.autoplay[swiperEl.swiper.autoplay.running ? 'stop' : 'start']()
            })
        }
    })