document.querySelectorAll('[data-callback]').forEach(button => {
    const controls = button.getAttribute('aria-controls')
    if (!controls) return
    const form = document.getElementById(controls)
    if (form?.querySelector('#umbraco-forms-form-submitted')) {
        button.setAttribute('aria-expanded', 'true')
        button.setAttribute('disabled', '')
        form.dataset.state = 'submitted'
        return
    }
    form?.setAttribute('hidden', '')
    button.addEventListener('click', () => {
        button.setAttribute('disabled', '')
        button.setAttribute('aria-expanded', 'true')
        form?.removeAttribute('hidden')
        if (form) requestAnimationFrame(() => form.dataset.state = 'active')
    }, { once: true })
})